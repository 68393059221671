
import { AlertSeverities, showAlert } from "../helpers/alerts";
import { AccountType } from "../types/user/login-type";
import { Vue } from "vue-class-component";
import {
  userPatchPictureHandler,
  userPatchUsernameHandler,
  userPatchEmailHandler,
  userPatchPasswordHandler,
  userDeleteHandler,
} from "../handlers/user/user-self-handlers";
import store from "../store";
import { image_types } from "../types/ui/image-extensions";

export default class Settings extends Vue {
  private account_old: AccountType = Object.create(store.getters.getAccount);
  private image_new = this.account_old.image;
  private username_new = this.account_old.username;
  private email_new = this.account_old.email;

  private email_password = "";
  private password_old = "";
  private password_new = "";
  private password_new_confirm = "";

  private password_delete = "";

  onChangePicture(): void {
    const input = this.$refs.pfp as HTMLInputElement;
    if (!input.files) return;

    let reader = new FileReader();
    reader.onload = (e) => {
      // eslint-disable-next-line
      if (!image_types.includes(input.files![0].type)) {
        input.value = "";
        showAlert("Nechápu tvůj obrázek :(", "Obrázek, který jsi poslal, byl ve špatném formátu.", AlertSeverities.warn);
      } else {
        userPatchPictureHandler(e.target?.result as ArrayBuffer);
      }
    };
    reader.onerror = () => showAlert("Chyba při čtení souboru!", "Nahraný obrázek se nepovedlo přečíst.", AlertSeverities.warn);
    reader.readAsArrayBuffer(input.files[0]);
  }

  async onChangeUsername(): Promise<void> {
    if (this.account_old.username === this.username_new) return showAlert("Žádná změna", "Tvoje jméno je stejné jako předtím!", AlertSeverities.info);
    await userPatchUsernameHandler({ username: this.username_new });
  }

  async onChangeEmail(): Promise<void> {
    if (this.account_old.email === this.email_new) return showAlert("Žádná změna", "Tvůj email je stejný jako předtím!", AlertSeverities.info);
    await userPatchEmailHandler({ password: this.email_password, email: this.email_new });
  }

  async onChangePassword(): Promise<void> {
    if (this.password_new !== this.password_new_confirm) return showAlert("Hesla se neshodují!", "Před změnou hesla se nové hesla musí shodovat!", AlertSeverities.info);
    await userPatchPasswordHandler({ password: this.password_old, password_new: this.password_new });
    this.password_old = "";
  }

  async onDeleteUser(): Promise<void> {
    await userDeleteHandler({ password: this.password_delete });
    this.password_delete = "";
  }

  previewImageChange(event: InputEvent): void {
    const input = event.target as HTMLInputElement;
    if (!input.files) return;
    let reader = new FileReader();
    reader.onload = (e) => {
      // eslint-disable-next-line
      if (!image_types.includes(input.files![0].type)) {
        input.value = "";
        showAlert("Nechápu tvůj obrázek :(", "Obrázek, který jsi poslal, byl ve špatném formátu.", AlertSeverities.warn);
      } else {
        this.account_old.image = e.target?.result as string;
      }
      return;
    };
    reader.onerror = () => showAlert("Chyba při čtení souboru!", "Nahrání obrázku nedopadlo dobře. Zkus to prosím znovu.", AlertSeverities.warn);
    reader.readAsDataURL(input.files[0]);
  }
}
