import { ChangeEmailType, ChangePassType, ChangeUsernameType, DeleteUserType } from "@/types/user/patch-type";
import { request } from "@/requests/api-request";
import { AxiosError } from "axios";
import store from "../../store";
import { AlertSeverities, showAlert } from "@/helpers/alerts";
import { getHeaderAuth } from "../handlers-helpers";

export async function userPatchPictureHandler(payload: ArrayBuffer): Promise<void> {
  return await request(undefined, "PATCH", "user/self", undefined, {}, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus to ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        // store.commit("changeImage", response);
        showAlert("Úspěšně nahráno, ale...", "Obrázek byl úspěšně nahrán na server, ale tuhle funkcionalitu ještě nepodporujeme. Možná příště.", AlertSeverities.warn);
        return response;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(99, error);
      }
      switch (error.response?.status) {
        case 401:
          showAlert("Nechápu tvůj obrázek :(", "Obrázek, který jsi poslal, byl ve špatném formátu.", AlertSeverities.fatal);
          break;
        case 403:
          showAlert("Nemáš přístup", "Na tuto operaci nemáš práva. Nekaž nám naši Fitušku. :c", AlertSeverities.fatal);
          break;
      }
    });
}

export async function userPatchUsernameHandler(payload: ChangeUsernameType): Promise<void> {
  return await request(undefined, "PATCH", "user/self", undefined, payload, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus to ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        store.commit("changeUsername", payload.username);
        showAlert("Úspěšně změněno uživatelské jméno!", "Jméno bylo úspěšně nahráno na server.", AlertSeverities.success);
        return response;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(99, error);
      }
      switch (error.response?.status) {
        case 401:
          showAlert("Chyba při změně uživatelského jména.", "Uživatelské jméno se nepovedlo změnit :(", AlertSeverities.warn);
          break;
        case 403:
          showAlert("Nemáš přístup", "Na tuto operaci nemáš práva. Nekaž nám naši Fitušku. :c", AlertSeverities.fatal);
          break;
      }
    });
}

export async function userPatchEmailHandler(payload: ChangeEmailType): Promise<void> {
  return await request(undefined, "PATCH", "user/self", undefined, payload, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus to ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        store.commit("changeEmail", payload.email);
        showAlert("Úspěšně změněn email!", "Email byl úspěšně nahrán na server.", AlertSeverities.success);
        return response;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(99, error);
      }
      switch (error.response?.status) {
        case 401:
          showAlert("Špatný email...", "Email, který jsi poslal, byl ve špatném formátu..", AlertSeverities.warn);
          break;
        case 403:
          showAlert("Nemáš přístup", "Na tuto operaci nemáš práva. Nekaž nám naši Fitušku. :c", AlertSeverities.fatal);
          break;
      }
    });
}

export async function userPatchPasswordHandler(payload: ChangePassType): Promise<void> {
  return await request(undefined, "PATCH", "user/self", undefined, payload, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus to ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        showAlert("Úspěšně změněno heslo!", "Heslo bylo úspěšně nahráno na server.", AlertSeverities.success);
        return response;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(99, error);
      }
      switch (error.response?.status) {
        case 401:
          showAlert("Špatné heslo...", "Heslo, které jsi zadal, se neshodovalo se tvým starým heslem.", AlertSeverities.warn);
          break;
        case 403:
          showAlert("Nemáš přístup", "Na tuto operaci nemáš práva. Nekaž nám naši Fitušku. :c", AlertSeverities.fatal);
          break;
      }
    });
}

export async function userDeleteHandler(payload: DeleteUserType): Promise<void> {
  return await request(undefined, "DELETE", "user/self", undefined, payload, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus to ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        store.commit("accountLogout");
        showAlert("Úspěšně smazán účet!", "Už vůbec nevíme kdo jsi...", AlertSeverities.success);
        return response;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(99, error);
      }
      switch (error.response?.status) {
        case 401:
          showAlert("Nejsi přihlášen", "Tuto operaci nemůžeš provést bez přihlášení. Prosím přihlaš se.", AlertSeverities.fatal);
          break;
        case 403:
          showAlert("Nemáš přístup", "Na tuto operaci nemáš práva. Nekaž nám naši Fitušku. :c", AlertSeverities.fatal);
          break;
      }
    });
}
